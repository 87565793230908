import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import solutions_back from "../images/solutions_back.png";
import Roobot2 from "../images/RooBot2.png";
import blob2 from "../images/Blob2.png"
import blob3 from "../images/Blob3.png"
import blob4 from "../images/Blob4.png"
import {Helmet} from "react-helmet";
import SEO from "./seo";

export default function Solutions() {
    return <div>
        <SEO title="Talent management and performance improvement solutions"
             description="A roobrick is a smart performance coach that can cut training costs, improve transparency, and keep everyone focused on what matters most"
        ></SEO>
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div style={{ backgroundImage: `url(${solutions_back})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
                        <div style={{ minHeight: 150 }}>
                            <Header/>
                        </div>
                        <div style={{ height: 120 }}>
                        </div>
                        <div>
                            <div className="home-hero-div">
                                <div className="home-hero-titlelight">Solutions</div>
                            </div>
                        </div>
                        <div style={{ height: 240 }}></div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ height: 50 }}></div>
        <div className="container-fluid">
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={blob2} align="right" className="flow-image"/>
                </div>
                <div className="col-md-6 order-md-first">
                    <div className="vertical-center">
                        <div className="textblock-left">
                            <div className="title">
                                Remote Teams
                            </div>
                            <div className="body">
                                A roobrick can extend the reach of your managers without adding more busywork.
                                By providing a single view of what matters, as well as tools for self-directed
                                learning, a roobrick can help to make up for some of what's lost when
                                people aren't working side-by-side.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 100 }}></div>
                </div>
            </div>
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={blob3} align="left" className="flow-image"/>

                </div>
                <div className="col-md-6">
                    <div className="vertical-center">
                        <div className="textblock-right">
                            <div className="title">
                                Contact Center
                            </div>
                            <div className="body">

                                A roobrick makes it easier for agents to understand how to
                                deliver a terrific customer service experience.
                                By analyzing the interactions that flow through your
                                contact center apps, a roobrick can identify
                                the words, phrases, and behaviors that produce the best results, and
                                foster these within the team.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 100 }}></div>
                </div>
            </div>
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={blob4} align="right" className="flow-image"/>
                </div>
                <div className="col-md-6 order-md-first">
                    <div className="vertical-center">
                        <div className="textblock-left">
                            <div className="title">
                                Enterprise Performance Management
                            </div>
                            <div className="body">
                                A roobrick transforms performance management from
                                an end-of-the-year exercise to a continuous process
                                by bringing together qualitative feedback from colleagues,
                                quantitative feedback from applications, and training
                                from subject matter experts.


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 100 }}></div>
                </div>
            </div>
        </div>

        <div style={{ height: 100 }}></div>

        <div className="roobot-2">
            <img src={Roobot2}/>
        </div>
        <br clear="all"/>

        <Footer />

    </div>
}